/* Loader.css */
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* Make sure it's on top of other content */
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading {
  font-size: 26px;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;

  color: #ffb800;
  text-align: center;
}

.loading span {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ffb800;
  margin-inline: 2px;
  /* margin-top: -10px; */
  margin-bottom: 10px;
}

.loading04 span {
  animation: loading04 0.7s infinite;
}

.loading04 span:nth-child(2) {
  animation-delay: 0.1s;
}

.loading04 span:nth-child(3) {
  animation-delay: 0.2s;
}

@keyframes loading04 {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(15px);
  }
}

.no-scroll {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.no-scroll::-webkit-scrollbar {
  width: 0px;
  display: none;
}

.typing-animation {
  animation: typing 1s steps(10, end) forwards;
  margin-right: 4px;
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.dropdown-container {
  position: relative;
  border: 1px solid #ffb800;
  background-color: transparent;
  border-radius: 4px;
  width: 200px;
}

.custom-select {
  height: 50px;
  width: 100%;
  background-color: transparent;
  color: white;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  appearance: none;
  /* Remove default styling for the dropdown */
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 15px;
}

.custom-select.open {
  border-color: white;
}

.options-container {
  position: absolute;
  top: 53px;
  width: 100%;
  background-color: black;
  border: 1px solid #252525;
  border-radius: 4px;
  box-sizing: border-box;
  z-index: 1000;
  padding: 0;
  margin: 0;
}

.option {
  padding: 6px;
  color: white;
  cursor: pointer;
  border: none;
  list-style: none;
}

.option:hover {
  background-color: #ffb800;
}

.option.selected {
  background-color: ffb800;
  color: black;
}

.circle,
.circle::before {
  content: " ";
  margin: 15px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0 auto;
  transition: all 0.3s;
  background-color: #ffb800;
}

.circle::before {
  animation: mymove 1.5s infinite;
  position: absolute;
  background-color: #00ff00;
}

@keyframes mymove {
  50% {
    transform: scale(2);
    opacity: 0;
  }

  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.loader-cloth {
  width: 15px;
  height: 15px;
  aspect-ratio: 1;
  z-index: 10000;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}

@keyframes l5 {
  0% {
    box-shadow: 20px 0 #ffb800, -20px 0 #0002;
    background: #ffb800;
  }

  33% {
    box-shadow: 20px 0 #ffb800, -20px 0 #0002;
    background: #0002;
  }

  66% {
    box-shadow: 20px 0 #0002, -20px 0 #ffb800;
    background: #0002;
  }

  100% {
    box-shadow: 20px 0 #0002, -20px 0 #ffb800;
    background: #ffb800;
  }
}