*, *:after, *:before {
    box-sizing: border-box;
  }
  
  body {
    font-family: "Inter", sans-serif;
    background-color: #222222; /* Dark mode background */
    color: #323232; /* Dark mode font color */
  }
  
  .card {
    display: flex;
    flex-direction: column;
    flex-basis: 12vw;
    flex-shrink: 0;
    flex-grow: 0;
    max-width: 100%;
    background-color: #222222; /* Dark mode card background */
    box-shadow: 0 5px 10px 0 rgba(129, 128, 128, 0.15);
    border-radius: 10px;
    overflow: hidden;
    margin: 1rem;
  }
  
  .card-img {
    padding-bottom: 56.25%;
    position: relative;
    background-color: #242526; /* Dark mode skeleton background */
  }
  
  .card-body {
    padding: 1.5rem;
  }
  
  .card-title {
    font-size: 1.25rem;
    line-height: 1.33;
    font-weight: 700;
    min-height: 28px;
    border-radius: 4px;
    background-color: #242526; /* Dark mode skeleton background */
  }
  
  .card-intro {
    margin-top: 0.75rem;
    line-height: 1.5;
    min-height: 15px;
    border-radius: 4px;
    background-color: #242526; /* Dark mode skeleton background */
  }
  
  .skeleton {
    background-color: #242526; /* Dark mode skeleton background */
    
    background-size: 40px 100%;
    background-repeat: no-repeat;
    background-position: left -40px top 0;
    animation: shine 1s ease infinite;
  }
  
  @keyframes shine {
    to {
      background-position: right -40px top 0;
    }
  }
  
  .container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  